






















import { DistributionService } from '@/includes/services/DistributionService'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'

import DistributionsList from 'piramis-base-components/src/components/Distributions/DistributionsList/DistributionsList.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { DistributionState } from 'piramis-base-components/src/components/Distributions/DistributionEditor/types'
import { FullDistribution } from 'piramis-base-components/src/components/Distributions/Distribution'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    PageTitle,
    DistributionsList,
  },
})
export default class DistributionsListPage extends Vue {
  isLoading = false

  goToDistribution(distributionId?: string): void {
    this.$router.push({
      name: 'Distribution',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ],
        ...distributionId ? { distributionId } : null,
        actionType: BaseItemViewAction.Watch,
      },
    })
  }

  goToCopyDistribution(distributionId?: string): void {
    this.$router.push({
      name: 'Distribution',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ],
        ...distributionId ? { distributionId } : null,
        actionType: BaseItemViewAction.Copy,
      },
    })
  }

  goToEditDistribution(distributionId?: string): void {
    this.$router.push({
      name: 'Distribution',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ],
        ...distributionId ? { distributionId } : null,
        actionType: BaseItemViewAction.Edit,
      },
    })
  }

  gotoCreateDistribution(): void {
    this.$router.push({
      name: 'Distribution',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ],
        actionType: BaseItemViewAction.New,
      },
    })
  }

  async getDistributions(state: DistributionState | null, offset: number): Promise<Array<FullDistribution>> {
    this.isLoading = true

    return new Promise(resolve => {
      DistributionService.getDistributions('tg', {
        board_key: this.$store.state.boardsState.activeBoard!.board,
        limit: 10,
        offset,
        ...state !== null ? { state } : {}
      }).then((items) => {
        resolve(items)
      })
        .finally(() => this.isLoading = false)
    })
  }

  async cancelDistribution(id: number):Promise<void> {
    this.isLoading = true

    return new Promise(resolve => {
      DistributionService.cancelDistribution('tg', {
        board_key: this.$store.state.boardsState.activeBoard!.board,
        id,
      })
        .finally(() => {
          resolve()
          this.isLoading = false
        })
    })
  }
}
