import BaseApi from '@/includes/services/BaseApi'

import { Distribution, FullDistribution } from 'piramis-base-components/src/components/Distributions/Distribution'
import { DistributionState } from 'piramis-base-components/src/components/Distributions/DistributionEditor/types'

import { instanceToPlain, plainToInstance } from 'class-transformer'

export class DistributionService {
    static createDistribution(type:string, body: { board_key: string, distribution: Distribution }):Promise<FullDistribution> {
        return BaseApi.sendRequest(type, 'createdistribution', {
            board_key: body.board_key,
            ...instanceToPlain(body.distribution)
        })
            .then(res => plainToInstance(FullDistribution, res.data))
    }

    static editDistribution(type:string, body: { board_key: string, distribution: Distribution }):Promise<FullDistribution> {
        return BaseApi.sendRequest(type, 'editdistribution', {
            board_key: body.board_key,
            ...instanceToPlain(body.distribution)
        })
            .then(res => plainToInstance(FullDistribution, res.data))
    }

    static getDistributions(type:string, body: { board_key:string, limit?: number, offset?: number, state?: DistributionState }):Promise<Array<FullDistribution>> {
        return BaseApi.sendRequest(type, 'getdistributions', body)
            .then(data => data.items.map(item => plainToInstance(FullDistribution, item)))
    }

    static getDistribution(type:string, body: { board_key:string, id:number }):Promise<FullDistribution> {
        return BaseApi.sendRequest(type, 'getdistribution', body)
            .then(data => plainToInstance(FullDistribution, data.data))
    }

    static preCheckDistribution(type: string, body: { board_key: string, conditions: Distribution['conditions'] }): Promise<{ count: number }> {
        return BaseApi.sendRequest(type, 'PreCheckDistribution', body)
    }

    static cancelDistribution(type:string, body: { board_key:string, id:number }):Promise<void> {
        return BaseApi.sendRequest(type, 'cancelDistribution', body)
    }
}
